import "./App.css";
import React, { useState } from "react";


function App() {
  const [Ukrainian, setLanguage] = useState(true);

  const [displayedMessage, setMessage] = useState(false);

  const messages = {
    "Find a job": {
      title: Ukrainian ? "Знайти роботу":"Find a job" ,
      content: Ukrainian ? (
        <p>Наразі ця сторінка доступна лише англійською мовою

<p>
          Hiring for:
          <br />
          <br />
          Dreamnaut HR
          <br />
          Graphic designer
          <br />
          Digital Marketer
          <br />
          Digital Artist / illustrator OR Artist Studio
          <br />
          UI/UX designer
          <br />
          <br />
          <br />
          PA &nbsp;(Fluent in English with clear accent + smart + flexible time
          + su per motivated and hardworking)&nbsp;
          <br />
          Graphic Designer x2-3
          <br />
          Digital Marketer / content creator 1-2 OR Social media marketing
          agency
          <br />
          Digital Artist / illustrator 1-3 OR Artist studio
          <br />
          Blockchain developer , solidity , rust
          <br />
          <br />
          For all above jobs, please send email with CV, portfolio and expected
          pay to&nbsp;
          <a
            href="mailto:Jonathan.lyt89@gmail.com"
            rel="nofollow"
            target="_blank"
          >
            Jonathan.lyt89@gmail.com
          </a>
          <br />
          <br />
          Unity game developers
          <br />
          Graphic artist, Fantasy lore writers,&nbsp;
          <br />
          Game concept designers with experience in Trading Card Games&nbsp;
          <br />
          <br />
          For all above jobs, please send email with CV, portfolio and expected
          pay to&nbsp;
          <br />
          <a href="mailto:Joshuagez@gmail.com" rel="nofollow" target="_blank">
            Joshuagez@gmail.com
          </a>{" "}
        </p>
        </p>
      ) : (
        <p>
          Hiring for:
          <br />
          <br />
          Dreamnaut HR
          <br />
          Graphic designer
          <br />
          Digital Marketer
          <br />
          Digital Artist / illustrator OR Artist Studio
          <br />
          UI/UX designer
          <br />
          <br />
          <br />
          PA &nbsp;(Fluent in English with clear accent + smart + flexible time
          + su per motivated and hardworking)&nbsp;
          <br />
          Graphic Designer x2-3
          <br />
          Digital Marketer / content creator 1-2 OR Social media marketing
          agency
          <br />
          Digital Artist / illustrator 1-3 OR Artist studio
          <br />
          Blockchain developer , solidity , rust
          <br />
          <br />
          For all above jobs, please send email with CV, portfolio and expected
          pay to&nbsp;
          <a
            href="mailto:Jonathan.lyt89@gmail.com"
            rel="nofollow"
            target="_blank"
          >
            Jonathan.lyt89@gmail.com
          </a>
          <br />
          <br />
          Unity game developers
          <br />
          Graphic artist, Fantasy lore writers,&nbsp;
          <br />
          Game concept designers with experience in Trading Card Games&nbsp;
          <br />
          <br />
          For all above jobs, please send email with CV, portfolio and expected
          pay to&nbsp;
          <br />
          <a href="mailto:Joshuagez@gmail.com" rel="nofollow" target="_blank">
            Joshuagez@gmail.com
          </a>{" "}
        </p>
      ),
    },
  };

  return (
    <body>
      {displayedMessage ? (
        <div id="messagedisplay" class="default">
          <div id="close" onClick={() => setMessage(null)}>
            X
          </div>
          <h2>{messages[displayedMessage].title}</h2>
          <div id="messageContainer" class="">
            {messages[displayedMessage].content}
          </div>
        </div>
      ) : (
        ""
      )}

      <div
        id="languageSelect"
        onClick={() => {
          setLanguage(!Ukrainian);
        }}
      >
        <img src={Ukrainian ? "./British Flag.png" : "./Ukraine Flag.jpg"} />
        {Ukrainian ? (
          <p>
            change language
            <br />
            змінити мову
          </p>
        ) : (
          <p>
            змінити мову
            <br />
            change language
          </p>
        )}
      </div>

      <div id="default" class="default">
        <h2>
          {Ukrainian
            ? "Цивільна допомога Україні"
            : "Direct Relief Group - Ukraine Civilian Safety"}
        </h2>
        <h1>
          {Ukrainian
            ? "tsey sayt isnuye, shchob dopomohty vam..."
            : "This site exists to help you..."}
        </h1>
        <p></p>

        <a href="https://docs.google.com/spreadsheets/d/1u28a1rRFxuVIFusCFe4vbfKHoJAJEkGDaDQg_DGJGbI/edit?fbclid=IwAR2-BEV3QK4EOztQK3cEcDy6TsLvo61lcz17PNi8gIzT0Qp_ib5_vLDf2ZE#gid=0">
          {Ukrainian
            ? "Знайдіть веб-сайти підтримки за місцем розташування"
            : "Find support websites by location"}
        </a>

        <a onClick={() => setMessage("Find a job")}>
          {Ukrainian?
          "Знайти роботу":"Find a job" }
        </a>

        <a href="https://t.me/connectUAworld">
          {Ukrainian ? "Група Telegram" : "Telegram Group"}
        </a>
      </div>
    </body>
  );
}

export default App;
